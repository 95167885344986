var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("asterix-section", {
        scopedSlots: _vm._u([
          {
            key: "header-title",
            fn: function () {
              return [_vm._v("Blocked List")]
            },
            proxy: true,
          },
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "div",
                  {
                    staticClass:
                      "w-full p-6 bg-white rounded-lg shadow-md lg:p-12 mb-12",
                  },
                  [
                    _c("card-form", {
                      scopedSlots: _vm._u([
                        {
                          key: "title",
                          fn: function () {
                            return [_vm._v("Blocked Items")]
                          },
                          proxy: true,
                        },
                        {
                          key: "form",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                { staticClass: "flex flex-wrap" },
                                _vm._l(_vm.blockTypes, function (blockType) {
                                  return _c(
                                    "sun-label-group",
                                    {
                                      key: blockType.type,
                                      staticClass: "w-full lg:w-1/2 px-4 mb-4",
                                      attrs: { text: blockType.title },
                                    },
                                    [
                                      !_vm.isLoading
                                        ? _c("tag-selector", {
                                            attrs: {
                                              placeholder:
                                                blockType.placeholder,
                                            },
                                            on: {
                                              new: function ($event) {
                                                return _vm.addBlockElement(
                                                  $event,
                                                  blockType.type
                                                )
                                              },
                                              remove: function ($event) {
                                                return _vm.removeBlockedElement(
                                                  $event,
                                                  blockType.type
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.namesBlockList[
                                                  blockType.type
                                                ],
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.namesBlockList,
                                                  blockType.type,
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "namesBlockList[blockType.type]",
                                            },
                                          })
                                        : _c("tag-selector-loader"),
                                    ],
                                    1
                                  )
                                }),
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }