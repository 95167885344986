<template>
  <div>
    <asterix-section>
      <template #header-title>Blocked List</template>
      <template #content>
        <div class="w-full p-6 bg-white rounded-lg shadow-md lg:p-12 mb-12">
          <card-form>
            <template #title>Blocked Items</template>
            <template #form>
              <div class="flex flex-wrap">
                <sun-label-group
                  v-for="blockType in blockTypes"
                  :key="blockType.type"
                  class="w-full lg:w-1/2 px-4 mb-4"
                  :text="blockType.title"
                >
                  <tag-selector
                    v-if="!isLoading"
                    v-model="namesBlockList[blockType.type]"
                    :placeholder="blockType.placeholder"
                    @new="addBlockElement($event, blockType.type)"
                    @remove="removeBlockedElement($event, blockType.type)"
                  />
                  <tag-selector-loader v-else />
                </sun-label-group>
              </div>
            </template>
          </card-form>
        </div>
      </template>
    </asterix-section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AsterixSection from '@/components/templates/AsterixSection';
import CardForm from '@/components/atoms/CardForm';
import TagSelector from '@/components/molecules/shared/TagSelector';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { ACTIVE_CLIENT } from '@/store/modules/context/keys';
import { Toast } from '@/model/shared/Toast';
import { getBlockList, createBlock, deleteBlock } from '@/services/modules/ecommerce/blockList/index';
import { activeClientMixin } from '@/mixins/common/activeClientMixin';
import { blockTypes } from './config';
import BlockedElement from '@/entities/ecommerce/BlockedElement';
import TagSelectorLoader from '@/components/atoms/Loaders/TagSelectorLoader';
import { HTTPStatusCode } from '@/model/shared/HTTPStatusCode';

export default {
  name: 'BlockList',
  components: {
    AsterixSection,
    CardForm,
    TagSelector,
    TagSelectorLoader,
  },
  mixins: [activeClientMixin],
  data() {
    return {
      blockedList: {},
      namesBlockList: {}, // we need a list with the block list names since sun-select can not work with tags and objects at the same time
      blockTypes,
      isLoading: true,
    };
  },
  computed: {
    ...mapGetters({
      activeClient: ACTIVE_CLIENT,
    }),
  },
  async created() {
    this.isLoading = true;
    await this.getBlockedList();
    this.isLoading = false;
  },
  methods: {
    ...mapActions({
      createToast: CREATE_TOAST,
    }),
    async getBlockedList() {
      const { data: defaultBlockList } = await getBlockList(this.activeClient.id);
      this.blockTypes.forEach(blockType => {
        this.blockedList[blockType.type] = [];
        this.namesBlockList[blockType.type] = [];
      });
      defaultBlockList.forEach(block => {
        // prevent push when block type is not defined
        this.blockedList[block.type]?.push(block);
        this.namesBlockList[block.type]?.push(block.name);
      });
    },
    async addBlockElement(item, type) {
      try {
        const elementToBlock = new BlockedElement(null, item, type);
        await createBlock(this.activeClient.id, elementToBlock);
        this.createToast(Toast.success('Blocked!', `${item} blocked correctly`));
        await this.getBlockedList();
      } catch (error) {
        if (error.code !== HTTPStatusCode.Cancel) {
          this.createToast(Toast.error(`Error!`, `Something happened while blocking ${item}`));
        }
      }
    },
    async removeBlockedElement(item, type) {
      try {
        const blockToRemove = this.blockedList[type].find(block => block.name === item);
        if (blockToRemove) {
          await deleteBlock(blockToRemove.id);
          this.createToast(Toast.success(`${item}`, 'block deleted'));
          await this.getBlockedList();
        }
      } catch (error) {
        if (error.code !== HTTPStatusCode.Cancel) {
          this.createToast(Toast.error(`Error!`, `Something happened while removing block from ${item}`));
        }
      }
    },
  },
};
</script>
