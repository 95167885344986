var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("sun-select", {
    attrs: {
      placeholder: _vm.placeholder,
      label: _vm.label,
      "color-tag": _vm.colorTag,
      options: [],
      multiple: "",
      taggable: "",
    },
    on: { tag: _vm.addTag, "tag-remove": _vm.removeTag },
    model: {
      value: _vm.currentValues,
      callback: function ($$v) {
        _vm.currentValues = $$v
      },
      expression: "currentValues",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }