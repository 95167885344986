<template functional>
  <div class="w-full h-10 bg-gray-300 animate-pulse rounded" :class="data.staticClass || ''" />
</template>

<script>
export default {
  name: 'TagSelectorLoader',
};
</script>

<style scoped></style>
