import { BLOCK_TYPES } from '@/model/modules/ecommerce/block/BlockTypes';

export const blockTypes = [
  {
    title: 'Blocked Domains',
    placeholder: 'example.com, ...',
    type: BLOCK_TYPES.DOMAIN,
  },
  {
    title: 'Blocked Ips',
    placeholder: '255.255.0.0, ...',
    type: BLOCK_TYPES.IP,
  },
  {
    title: 'Blocked User Agents',
    placeholder: 'Mozilla/5.0, ...',
    type: BLOCK_TYPES.USER_AGENT,
  },
  {
    title: 'Blocked Pub Ids',
    placeholder: 'Mozilla/5.0, ...',
    type: BLOCK_TYPES.PUB_ID,
  },
  {
    title: 'Blocked Domain Strings',
    placeholder: 'blockMe, ...',
    type: BLOCK_TYPES.DOMAIN_STRING,
  },
  {
    title: 'Blocked Bundles',
    placeholder: 'com.bundle, ...',
    type: BLOCK_TYPES.BUNDLE,
  },
];
