<template>
  <sun-select
    v-model="currentValues"
    :placeholder="placeholder"
    :label="label"
    :color-tag="colorTag"
    :options="[]"
    multiple
    taggable
    @tag="addTag"
    @tag-remove="removeTag"
  />
</template>

<script>
export default {
  name: 'TagSelector',
  props: {
    placeholder: {
      type: String,
      default: () => '',
    },
    label: {
      type: String,
      default: () => '',
    },
    colorTag: {
      type: String,
      default: () => 'gray',
    },
    value: {
      type: Array,
      default: () => [],
    },
    allowDuplicated: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      currentValues: [],
    };
  },
  watch: {
    value(newValue) {
      this.currentValues = newValue;
    },
    currentValues(newValues) {
      this.$emit('input', newValues);
      this.$emit('change');
    },
  },
  created() {
    this.currentValues = this.value;
  },
  methods: {
    addTag(newTag) {
      const multipleTags = newTag.match(/[^,(?! )]+/g);
      multipleTags.forEach(tagToAdd => {
        const isDuplicated = this.currentValues.includes(tagToAdd);

        if (isDuplicated) {
          this.$emit('duplicated', tagToAdd);
        }
        if (!isDuplicated || this.allowDuplicated) {
          this.currentValues.push(tagToAdd);
          this.$emit('new', tagToAdd);
        }
      });
    },
    removeTag(removedTag) {
      this.$emit('remove', removedTag);
    },
  },
};
</script>

<style scoped>
::v-deep .multiselect {
  @apply cursor-pointer;
}
::v-deep .multiselect__option {
  @apply hidden;
}
::v-deep .multiselect__select {
  @apply hidden;
}
::v-deep .multiselect__tags input {
  @apply mb-0;
}

::v-deep ::placeholder {
  @apply text-gray-500;
}
</style>
